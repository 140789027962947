class Nav {
  constructor(nav){
    this.nav = nav
    this.timeout = null
    this.navItems = this.nav.querySelectorAll('.nav-item')
    this.items = this.nav.querySelectorAll('.nav-item.with-sub')
    this.subItems = this.nav.querySelectorAll('.subItems')
    this.current = null
    this.debounce = 200
    this.open = null
    this.button = this.nav.querySelector('.menu-button')
    this.menu = this.nav.querySelector('.main-menu')
    this.backNavs = this.nav.querySelectorAll('.back-nav')
  }

  init = () => {
    const currentOpen = this.nav.querySelector('.subItems.active')
    if(currentOpen) this.open = this.current = currentOpen.dataset.id
    this.items.forEach(item => {
      item.addEventListener('mouseenter', this.enterHandler)
      item.addEventListener('mouseleave', this.leaveHandler)
      item.addEventListener('click', this.clickHandler)
    })

    this.subItems.forEach(item => {
      item.addEventListener('mouseenter', this.enterHandler)
      item.addEventListener('mouseleave', this.leaveHandler)
    })

    this.button.addEventListener('click', this.buttonHandler)
    window.addEventListener('click', () => {
      if(!window.matchMedia('(min-width: 1024px)').matches){
        this.menu.classList.add('-translate-y-full')
        this.button.classList.remove('active')
      }
    })
    this.backNavs.forEach(backNav => backNav.addEventListener('click', this.backNavClickHandler))

    window.addEventListener('resize', this.resizeHandler)
  }

  resizeHandler = () => {
    if(window.matchMedia('(min-width: 1024px)').matches){
      this.backNavClickHandler()
    }
  }

  buttonHandler = (e) => {
    e.stopPropagation()
    this.menu.classList.toggle('-translate-y-full')
    this.menu.classList.toggle('shadow-lg')
    this.button.classList.toggle('active')
  }

  clickHandler = (e) => {
    e.stopPropagation()
    const el = e.currentTarget
    const id = el.dataset.id
    if(!window.matchMedia('(min-width: 1024px)').matches){      
      this.nav.querySelector(`.subItems-mobile.parent-${id}`).classList.toggle('hidden')
      this.navItems.forEach(navItem => navItem.classList.toggle('hidden'))
    }
  }

  backNavClickHandler = e => {
    e && e.stopPropagation()
    this.nav.querySelectorAll(`.subItems-mobile`).forEach(s => s.classList.add('hidden'))
    this.navItems.forEach(navItem => navItem.classList.remove('hidden'))
  }

  enterHandler = (e) => {
    const el = e.currentTarget
    const id = el.dataset.id
    
    if(window.matchMedia('(min-width: 1024px)').matches){
      this.nav.querySelector(`.subItems.parent-${id}`).classList.remove('-translate-y-full')
      this.nav.querySelector(`.nav-item.nav-item-${id}`).classList.add('text-accent')
      if(this.timeout) window.clearTimeout(this.timeout)

      if(this.current && id !== this.current){
        this.nav.querySelector(`.subItems.parent-${this.current}`).classList.add('-translate-y-full')
        this.nav.querySelector(`.nav-item.nav-item-${this.current}`).classList.remove('text-accent')
      }

      this.current = id
    }
  }

  leaveHandler = (e) => {
    const el = e.currentTarget
    const id = el.dataset.id
    
    if(window.matchMedia('(min-width: 1024px)').matches){
      this.timeout = window.setTimeout(() => {
        this.nav.querySelector(`.subItems.parent-${id}`).classList.add('-translate-y-full')
        this.nav.querySelector(`.nav-item.nav-item-${id}`).classList.remove('text-accent')

        if(this.open){
          this.current = this.open
          this.nav.querySelector(`.subItems.parent-${this.open}`).classList.remove('-translate-y-full')
          this.nav.querySelector(`.nav-item.nav-item-${this.open}`).classList.add('text-accent')
        }else{
          this.open = null
        }
      }, this.debounce)
    }
  }

  updateActive = (url) => {
    this.navItems.forEach(link => {
      if(link.href == url) {
        link.classList.add('lg:text-accent')
        link.classList.remove('lg:text-charcoal-grey')
      } else {
        link.classList.add('lg:text-charcoal-grey')
        link.classList.remove('lg:text-accent')
      }
    })

    this.nav.querySelectorAll('.subItems .nav-item-sub').forEach(link => {
      if(link.href == url){
        const el = this.nav.querySelector(`.nav-item-${link.parentElement.parentElement.dataset.id}`)
        el.classList.add('lg:text-accent')
        el.classList.remove('lg:text-charcoal-grey')
      }
    })
  }
}

export default Nav