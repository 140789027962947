import Swup from 'swup'
import SwupJsPlugin from '@swup/js-plugin'
import SwupScrollPlugin from '@swup/scroll-plugin'
import { gsap } from 'gsap/all'
import router from '../router'

let route = null
gsap.defaults({
  duration: 0.3,
  ease: 'power2'
})
export default (menu, preloader) => {
  const options = [
    {
      from: '(.*)', // meaning any
      to: '(.*)', // meaning any
      out: (next) => {
        preloader.in(next)
      },
      in: async (next) => {
        route = await router()
        preloader.out(next)
        menu.updateActive(window.location.href)
      }
    }
  ]

  const swup = new Swup({
    linkSelector: 'a[href^="/"]:not([data-no-swup]), a[href^="' + window.location.origin + '"]:not([data-no-swup])',
    plugins: [new SwupJsPlugin(options), new SwupScrollPlugin({
      doScrollingRightAway: false,
      animateScroll: false
    })],
    containers: ['.content-main'],
    requestHeaders: {
      "X-Requested-With": "XMLHttpRequest"
    },
    cache: false
  });
}