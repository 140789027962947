import { gsap } from 'gsap/all'

class Preloader {
  constructor(el){
    this.preloader = el
  }

  in = (callback) => {
    gsap.to(this.preloader, {
      autoAlpha: 1,
      ease: 'power2.in',
      duration: 0.3,
      pointerEvents: 'auto',
      onComplete: callback
    })
  }

  out = (callback) => {
    gsap.to(this.preloader, {
      autoAlpha: 0,
      ease: 'power2.out',
      duration: 0.3,
      pointerEvents: 'none',
      onComplete: callback
    })
  }
}

export default Preloader