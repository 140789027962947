import '../css/app.pcss'
import router from './router'
import Nav from './components/nav'
import Swup from './components/Swup'
import Preloader from './components/Preloader'

const nav = new Nav(document.querySelector('.navigation'))
nav.init()

const preloader = new Preloader(document.querySelector('.preloader'))
if (navigator.appVersion.indexOf("Win")!=-1) document.body.classList.add('windows')
Swup(nav, preloader)
window.addEventListener('load', () => {
  router()
  preloader.out()
})
